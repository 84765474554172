import "./App.css";
import MainDash from "./components/MainDash/MainDash";
import RightSide from "./components/RightSide/RightSide";
import Siderbar from "./components/Siderbar/Siderbar";

function App() {
  return (
    <div className="App">
      <div className="AppGlass">
        <Siderbar />
        <MainDash />
        <RightSide />
      </div>
    </div>
  );
}

export default App;
