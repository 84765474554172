import React from "react";
import "./Updates.css";
import { UpdatesData } from "../../Data/Data";
const Updates = () => {
  // console.log(UpdatesData);
  return (
    <div className="Updates">
      {UpdatesData.map(({ name, img, noti, time }, index) => (
        <div key={index} className="update">
          <img src={img} alt={name} />
          <div className="noti">
            <div style={{ marginBottom: "0.5rem" }}>
              <span> {name} </span>
              <span> {noti} </span>
            </div>
            <span>{time}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Updates;
