import React from "react";
import { Cardsdata } from "../../Data/Data";
import Card from "../Card/Card";
import "./Cards.css";
const Cards = () => {
  return (
    <div className="Cards">
      {Cardsdata.map((card, index) => (
        <div key={index} className="parentContainer">
          <Card {...card} />
        </div>
      ))}
    </div>
  );
};

export default Cards;
